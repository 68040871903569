import {
  AD_SLOT_CARTINTERMIDIATE,
  AD_SLOT_CATEGORY_SMALL_1,
  AD_SLOT_CATEGORY_SMALL_2,
  AD_SLOT_CATEGORY_WIDE,
  AD_SLOT_CONFIRMATION,
  AD_SLOT_GLOBAL_TOP,
  AD_SLOT_HOMEPAGE_SERVICES_BANNER,
  AD_SLOT_HOMEPAGE_SMALL_1,
  AD_SLOT_HOMEPAGE_SMALL_2,
  AD_SLOT_HOMEPAGE_SMALL_5,
  AD_SLOT_HOMEPAGE_SMALL_6,
  AD_SLOT_HOMEPAGE_SMART,
  AD_SLOT_HOMEPAGE_WIDE,
  AD_SLOT_PDP_CROSSSELL,
  AD_SLOT_PDP_RX,
  AD_SLOT_PROMO_SMALL_1,
  AD_SLOT_PROMO_SMALL_2,
  AD_SLOT_PROMO_SMALL_3,
  AD_SLOT_PROMO_SMALL_4,
  AD_SLOT_PROMO_SMALL_5,
  AD_SLOT_PROMO_SMALL_6,
  AD_SLOT_PROMO_SMALL_7,
  AD_SLOT_PROMO_SMALL_8,
  AD_SLOT_SEARCH_BRAND_TOP,
  AD_SLOT_SEARCH_CATEGORY_TOP,
  AD_SLOT_SEARCH_HITS_1,
  AD_SLOT_SEARCH_HITS_2,
  AD_SLOT_SEARCH_TOP_BANNER,
  AD_SLOT_TOP_BANNER,
} from 'shared/consts/adSlots'

interface Dimensions {
  width: number
  height: number
}

export interface Sizes {
  [key: string]: {
    large: Dimensions
    small: Dimensions
  }
}

export const AD_SLOTS_PROMO = [
  AD_SLOT_PROMO_SMALL_1,
  AD_SLOT_PROMO_SMALL_2,
  AD_SLOT_PROMO_SMALL_3,
  AD_SLOT_PROMO_SMALL_4,
  AD_SLOT_PROMO_SMALL_5,
  AD_SLOT_PROMO_SMALL_6,
  AD_SLOT_PROMO_SMALL_7,
  AD_SLOT_PROMO_SMALL_8,
]

const adSlotsPromo = AD_SLOTS_PROMO.reduce<Sizes>(
  (acc, slot) => ({
    ...acc,
    [slot]: {
      large: { width: 480, height: 200 },
      small: { width: 347, height: 135 },
    },
  }),
  {}
)

export const SIZES_BY_ID: Sizes = {
  ...adSlotsPromo,
  // layout (each page): top
  [AD_SLOT_GLOBAL_TOP]: {
    large: { width: 0, height: 0 },
    small: { width: 0, height: 0 },
  },

  // home page: CategoryWide CMS component
  [AD_SLOT_HOMEPAGE_WIDE]: {
    large: { width: 980, height: 300 },
    small: { width: 347, height: 135 },
  },

  // home page: Services Widget Banner
  [AD_SLOT_HOMEPAGE_SERVICES_BANNER]: {
    small: { width: 347, height: 135 },
    large: { width: 480, height: 200 },
  },
  // home page: CategorySmall CMS component (first)
  [AD_SLOT_HOMEPAGE_SMALL_1]: {
    large: { width: 480, height: 200 },
    small: { width: 347, height: 135 },
  },
  // home page: CategorySmall CMS component (second)
  [AD_SLOT_HOMEPAGE_SMALL_2]: {
    large: { width: 480, height: 200 },
    small: { width: 347, height: 135 },
  },
  [AD_SLOT_HOMEPAGE_SMALL_5]: {
    large: { width: 480, height: 200 },
    small: { width: 347, height: 135 },
  },
  [AD_SLOT_HOMEPAGE_SMALL_6]: {
    large: { width: 480, height: 200 },
    small: { width: 347, height: 135 },
  },
  // home page: EyecatcherSmart CMS component
  [AD_SLOT_HOMEPAGE_SMART]: {
    large: { width: 480, height: 460 },
    small: { width: 347, height: 135 },
  },

  // category page: CategoryWide CMS component
  [AD_SLOT_CATEGORY_WIDE]: {
    large: { width: 728, height: 220 },
    small: { width: 347, height: 135 },
  },
  // category page: CategorySall CMS component (first)
  [AD_SLOT_CATEGORY_SMALL_1]: {
    large: { width: 354, height: 150 },
    small: { width: 347, height: 135 },
  },
  // category page: CategorySall CMS component (second)
  [AD_SLOT_CATEGORY_SMALL_2]: {
    large: { width: 354, height: 150 },
    small: { width: 347, height: 135 },
  },

  // search page (brand product listing): top
  [AD_SLOT_SEARCH_BRAND_TOP]: {
    large: { width: 0, height: 0 },
    small: { width: 0, height: 0 },
  },
  // search page (category product listing): top
  [AD_SLOT_SEARCH_CATEGORY_TOP]: {
    large: { width: 728, height: 140 },
    small: { width: 347, height: 135 },
  },

  // search page top banner
  [AD_SLOT_SEARCH_TOP_BANNER]: {
    large: { width: 728, height: 140 },
    small: { width: 347, height: 135 },
  },
  // search page: between search results (first)
  [AD_SLOT_SEARCH_HITS_1]: {
    large: { width: 728, height: 140 },
    small: { width: 347, height: 135 },
  },
  // search page: between search results (second)
  [AD_SLOT_SEARCH_HITS_2]: {
    large: { width: 728, height: 140 },
    small: { width: 347, height: 135 },
  },
  // product page: cross-sell widget
  [AD_SLOT_PDP_CROSSSELL]: {
    large: { width: 480, height: 200 },
    small: { width: 347, height: 135 },
  },
  // product page: RX message
  [AD_SLOT_PDP_RX]: {
    large: { width: 480, height: 210 },
    small: { width: 347, height: 140 },
  },
  // order confirmation page
  [AD_SLOT_CONFIRMATION]: {
    large: { width: 980, height: 300 },
    small: { width: 347, height: 135 },
  },
  // cart intermidiate page
  [AD_SLOT_CARTINTERMIDIATE]: {
    large: { width: 357, height: 150 },
    small: { width: 347, height: 135 },
  },
  [AD_SLOT_TOP_BANNER]: {
    large: { width: 1920, height: 100 },
    small: { width: 1920, height: 100 },
  },
}
