// @ts-nocheck
import 'views/utils/setupDynamicAssetPath'

import advertisingFlyoutBanner from 'shared/experiments/browser/advertisingFlyoutBanner'
import afterQuerySuggestLayer from 'shared/experiments/browser/afterQuerySuggestLayer'
import algoliaNeuralSearchEventsTracking from 'shared/experiments/browser/algoliaNeuralSearch/eventsTracking'
import allProductsFromSeller from 'shared/experiments/browser/allProductsFromSeller'
import cartFreeGiftPromotion from 'shared/experiments/browser/cartFreeGiftPromotion'
import cartStickyCta from 'shared/experiments/browser/cartStickyCta'
import crosssellLayout from 'shared/experiments/browser/crosssellLayout'
import cswProductTitleExperiment from 'shared/experiments/browser/cswProductTitle'
import exitIntentPopup from 'shared/experiments/browser/exitIntentPopup'
import erxCampaignModal from 'shared/experiments/browser/experimentErxModal'
import headerTracking from 'shared/experiments/browser/header/eventsTracking'
import headerSmallTracking from 'shared/experiments/browser/headerSmall'
import homeOneAccountOverview from 'shared/experiments/browser/homeOneAccountOverview'
import newsletterModal from 'shared/experiments/browser/newsletterModal'
import oneTimePasswordInterestTracking from 'shared/experiments/browser/oneTimePasswordExperiment/eventsTracking'
import removePacksizeAndCtaExperiment from 'shared/experiments/browser/removePacksizeAndCtaExperiment'
import logger from 'shared/services/logger'
import adServer from 'views/assets/scripts/adServer'
import adSlots from 'views/assets/scripts/adSlots'
import anchors from 'views/assets/scripts/anchors'
import asyncDataFetching from 'views/assets/scripts/asyncDataFetching'
import brandshopNavigation from 'views/assets/scripts/brandshopsNavigation'
import copyLink from 'views/assets/scripts/copyLink'
import handleMobileErxAnnouncement from 'views/assets/scripts/erxAnnouncement'
import expander from 'views/assets/scripts/expander'
import fetchMiniCartData from 'views/assets/scripts/fetchMiniCartData'
import fetchUserSession from 'views/assets/scripts/fetchUserSession'
import floatingLabel from 'views/assets/scripts/floatingLabel'
import formValidation from 'views/assets/scripts/formValidation'
import formBirthdaySelectValidation from 'views/assets/scripts/formValidation/formBirthdaySelectValidation'
import formControlValidation from 'views/assets/scripts/formValidation/formControlValidation'
import fullWidthElement from 'views/assets/scripts/fullWidthElement'
import handleUserSession from 'views/assets/scripts/handleUserSession'
import hover from 'views/assets/scripts/hover'
import iframeWithHtml from 'views/assets/scripts/iframeWithHtml'
import initStore from 'views/assets/scripts/initStore'
import menubarFlyout from 'views/assets/scripts/menubarFlyout'
import mobileDevice from 'views/assets/scripts/mobileDevice'
import modal from 'views/assets/scripts/modal'
import offCanvas from 'views/assets/scripts/offCanvas'
import {
  activateExperiments,
  startTrackingExperiments,
} from 'views/assets/scripts/optimizely'
import passwordReveal from 'views/assets/scripts/passwordReveal'
import polyfills from 'views/assets/scripts/polyfills'
import { initScrollToTop as scrollToTop } from 'views/assets/scripts/scrollToTop'
import searchBoxMobile from 'views/assets/scripts/searchBoxMobile'
import select from 'views/assets/scripts/select'
import slideover from 'views/assets/scripts/slideover'
import sticky from 'views/assets/scripts/sticky'
import stickyBanner from 'views/assets/scripts/stickyBanner'
import storeOptimiselyTrackingEvents from 'views/assets/scripts/storeOptimiselyTrackingEvents'
import switchLanguage from 'views/assets/scripts/switchLanguage'
import tabs from 'views/assets/scripts/tabs'
import tooltip from 'views/assets/scripts/tooltip'
import youTubeVideo from 'views/assets/scripts/youTubeVideo'
import mountNowGlobalBar from 'views/containers/browser/NowGlobalBar/mountNowGlobalBar'
import mountNowSellerModalContainer from 'views/containers/browser/NowSellerModalContainer/mountNowSellerModalContainer'
import mountSearchBoxContainer from 'views/containers/browser/SearchBoxContainer/mountSearchBoxContainer'
import mountSellerModalContainer from 'views/containers/browser/SellerModalContainer/mountSellerModalContainer'
import ready from 'views/utils/ready'

type CallbackList = Array<CallableFunction>

const COMMON_SCRIPTS: CallbackList = [
  polyfills,
  initStore,
  handleUserSession,
  handleMobileErxAnnouncement,
  advertisingFlyoutBanner,
  afterQuerySuggestLayer,
  cartFreeGiftPromotion,
  expander,
  exitIntentPopup,
  formValidation,
  formControlValidation,
  floatingLabel,
  tooltip,
  copyLink,
  mobileDevice,
  anchors,
  offCanvas,
  slideover,
  modal,
  passwordReveal,
  select,
  asyncDataFetching,
  storeOptimiselyTrackingEvents,
  menubarFlyout,
  sticky,
  hover,
  scrollToTop,
  iframeWithHtml,
  brandshopNavigation,
  mountSearchBoxContainer,
  mountSellerModalContainer,
  mountNowSellerModalContainer,
  youTubeVideo,
  fullWidthElement,
  adSlots,
  activateExperiments,
  startTrackingExperiments,
  formBirthdaySelectValidation,
  tabs,
  searchBoxMobile,
  headerTracking,
  headerSmallTracking,
  fetchMiniCartData,
  cswProductTitleExperiment,
  erxCampaignModal,
  adServer,
  mountNowGlobalBar,
  stickyBanner,
  newsletterModal,
  switchLanguage,
  homeOneAccountOverview,
  oneTimePasswordInterestTracking,
  crosssellLayout,
  allProductsFromSeller,
  removePacksizeAndCtaExperiment,
  algoliaNeuralSearchEventsTracking,
  cartStickyCta,
]

export const runSafeEachScript = (callbacks: CallbackList) => {
  callbacks.forEach(callback => {
    try {
      callback()
    } catch (error) {
      logger.error(`Cannot run client script ${callback.name}`, { error })
    }
  })
}

export default (...callbacks: CallbackList) =>
  ready(() =>
    // NB: `fetchUserSession` has to be executed last in order for all listeners to be attached
    runSafeEachScript([...COMMON_SCRIPTS, ...callbacks, fetchUserSession])
  )
