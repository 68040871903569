import store from 'shared/store'
import { VOLUME_PRICING_MODAL_CONTAINER_ID } from 'views/components/organisms/VolumePricingModal/names'
import { getVolumePricingFromBestOffer } from 'views/components/organisms/VolumePricingModal/utils/volumePricingAccessor'
import loadCatalogs from 'views/containers/browser/utils/loadCatalogs'
import buildClientAPI from 'views/providers/clientAPI'

import { OPEN_VOLUME_PRICING_MODAL } from './clientsideHook'

const mountVolumePricingModalContainer = async () => {
  const volumePricingButtons = document.querySelectorAll(
    `[data-clientside-hook*="${OPEN_VOLUME_PRICING_MODAL}"]`
  )
  const modal = document.getElementById(VOLUME_PRICING_MODAL_CONTAINER_ID)
  const { pimProductService } = buildClientAPI()

  if (!modal) {
    return
  }

  const {
    publicConfig: { locale },
  } = store.getPublicRuntimeConfig()

  const i18n = await loadCatalogs(locale)

  const volumePricingModalContainer = await import(
    './VolumePricingModalContainerWithHydration'
  )

  const upids = Array.from(volumePricingButtons, link =>
    link.getAttribute('data-product-identifier')
  )
  pimProductService.fetchMultiplePimProducts(upids).then(response => {
    const products = response.data.items
    volumePricingButtons.forEach(button => {
      const upid = button.getAttribute('data-product-identifier')
      const product = products.find(product => product.upid === upid)
      const sellerId = button.getAttribute('data-seller-identifier')

      let volumeBasedPrices
      if (!sellerId) {
        volumeBasedPrices = getVolumePricingFromBestOffer(product)
      } else {
        const offer = product.offers.find(
          offer => offer.seller.sellerId === sellerId
        )
        volumeBasedPrices = offer?.volumeBasedPrices
      }

      button.addEventListener('click', () => {
        const container = volumePricingModalContainer.default({
          searchResultUpid: upid,
          volumeBasedPrices,
          container: modal,
          i18n,
        })
        container.show()
      })
    })
  })
}

export default mountVolumePricingModalContainer
