/**
 * Each event name should be used with an `event` tag,
 * which validates the value and prevents incorrect names.
 */
import { event } from 'shared/experiments/utils/event'

export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_LOGGED_IN = event`HEADER - Click on account icon logged in`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_NOT_LOGGED_IN = event`HEADER - Click on account icon not logged in`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_SEARCH_ICON = event`HEADER - Click on search icon`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_CART_ICON = event`HEADER - Click on cart icon`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO = event`HEADER - Click on logo`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_HAMBURGER = event`HEADER - Click on burger menu`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_LOGGED_IN = event`HEADER - Click on my account inside burger logged in`
export const EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_NOT_LOGGED_IN = event`HEADER - Click on my account inside burger not logged in`

export const CSW_PRODUCT_CLICK = event`Click on product of CSW`
export const CSW_CLICK_ADD_TO_CART = event`Click on add to cart button on CSW`
export const CSW_SHOW_MORE_CLICK = event`Click on show more button of CSW`
export const CSW_SHOW_ALL_CLICK = event`Click on show all button of CSW`
export const CSW_ARROW_CLICK = event`Click on arrow of CSW`

export const SERP_PRODUCT_DETAIL_CLICK = event`SERP - Click on product detail link`
export const SERP_PRODUCT_DETAIL_CLICK_BADGE = event`SERP - Click on product detail link with badge`
export const HOME_SERVICE_LINK_CLICK = event`Click on service link`
export const SERP_FILTER_APPLY = event`SERP - Apply filter`
export const SERP_PAGINATION = event`SERP - Pagination`
export const SERP_ADD_TO_CART_CLICK = event`SERP - Click on add-2-cart CTA`
export const SERP_ADD_TO_CART_CLICK_BADGE = event`SERP - Click on add-2-cart CTA with badge`

export const CART_CHECKOUT_BUTTON_CLICK = event`Cart - Click on checkout button`
export const CART_REMOVE_PRODUCT_CLICK = event`Cart - Click on remove product button`
export const CART_VOUCHER_SUBMIT_CLICK = event`Cart - Click on voucher submit button`
export const CART_CLICK_ON_TOP_CTA_BUTTON = event`Cart - Click on top CTA button`
export const CART_CLICK_ON_MAIN_CTA_BUTTON = event`Cart - Click on main CTA button`
export const CART_CLICK_ON_STICKY_CTA_BUTTON = event`Cart - Click on sticky CTA button`

export const HEADER_SEARCH_BOX_CLICK = event`HEADER - Click on search box`
export const HEADER_SEARCH_BOX_SUGGESTED_PRODUCT_CLICK = event`HEADER - Click on suggested search box product`
export const HEADER_SEARCH_BOX_SUGGESTED_PRODUCT_ADD_TO_CART = event`HEADER - Suggested search box product added to cart`
export const HEADER_SEARCH_BUTTON_CLICK = event`HEADER - Click on search button`
export const HEADER_NOTEPAD_ICON_CLICK = event`HEADER - Click notepad icon`
export const HEADER_NOTEPAD_LINK_CLICK = event`HEADER - Click notepad link`
export const HEADER_LOGIN_ICON_CLICK = event`HEADER - Click login icon`
export const HEADER_MY_APOTHEKE_ICON_CLICK = event`HEADER - Click my apotheke icon`
export const HEADER_CART_ICON_CLICK = event`HEADER - Click on cart icon`
export const HEADER_CATEGORIES_HOVER = event`HEADER - Mouse over categories`
export const HEADER_PRODUCTS_HOVER = event`HEADER - Mouse over products`
export const HEADER_SEARCH_ICON_CLICK = event`HEADER - Click on search icon`
export const HEADER_MOBILE_BURGER_MENU_CLICK = event`HEADER - Click on mobile burger menu`
export const HEADER_REDEEM_PRESCRIPTION_CLICK = event`HEADER - Click on redeem prescription`

export const ADD_TO_CART_ERX_BANNER_CLICK = event`A2C - Click on eRx banner`

export const CART_GOT_ELIGIBLE_BY_DELETE = event`CART_GOT_ELIGIBLE_BY_DELETE`
export const CART_GOT_ELIGIBLE_BY_QUANTITY_CHANGE = event`CART_GOT_ELIGIBLE_BY_QUANTITY_CHANGE`
export const NOW_CART_LABEL_CLICK = event`NOW_CART_LABEL_CLICK`
export const NOW_CART_COLLAPSIBLE_CHECK_ELIGIBILITY_BTN_CLICK = event`NOW_CART_CHECK_ELIGIBILITY_BTN_CLICK`
export const NOW_CART_COLLAPSIBLE_CHANGE_ZIPCODE_BTN_CLICK = event`NOW_CART_COLLAPSIBLE_CHANGE_ZIPCODE_BTN_CLICK`
export const NOW_CART_GO_CHECKOUT_BTN_CLICK = event`NOW_CART_GO_CHECKOUT_BTN_CLICK`

export const NOW_SERP_LABEL_CLICK = event`NOW_SERP_LABEL_CLICK`
export const NOW_VALID_PLZ = event`NOW_VALID_PLZ`
export const NOW_INVALID_PLZ = event`NOW_INVALID_PLZ`
export const NOW_PDP_GLB_CLICK = event`NOW_PDP_GLB_CLICK`
export const NOW_PDP_LABEL_CLICK = event`NOW_PDP_LABEL_CLICK`
export const NOW_PDP_BUYBOX_TIMER_CLICK = event`NOW_PDP_BUYBOX_TIMER_CLICK`
export const NOW_CATEGORY_GLB_CLICK = event`NOW_CATEGORY_GLB_CLICK`
export const NOW_HOMEPAGE_GLB_CLICK = event`NOW_HOMEPAGE_GLB_CLICK`
export const NOW_SERP_GLB_CLICK = event`NOW_SERP_GLB_CLICK`
export const NOW_PDP_SHIPPING_MODAL_SUBMIT = event`NOW_PDP_SHIPPING_MODAL_SUBMIT`
export const NOW_SERP_GLB_MODAL_SUBMIT = event`NOW_SERP_GLB_MODAL_SUBMIT`
export const NOW_PDP_GLB_MODAL_SUBMIT = event`NOW_PDP_GLB_MODAL_SUBMIT`
export const NOW_CATEGORY_GLB_MODAL_SUBMIT = event`NOW_CATEGORY_GLB_MODAL_SUBMIT`
export const NOW_HOMEPAGE_GLB_MODAL_SUBMIT = event`NOW_HOMEPAGE_GLB_MODAL_SUBMIT`
export const NOW_CATEGORY_LABEL_CLICK = event`NOW_CATEGORY_LABEL_CLICK`
export const NOW_SERP_SHIPPING_MODAL_SUBMIT = event`NOW_SERP_SHIPPING_MODAL_SUBMIT`
export const NOW_CATEGORIES_SHIPPING_MODAL_SUBMIT = event`NOW_CATEGORIES_SHIPPING_MODAL_SUBMIT`
export const SPECIAL_SALE_OFFERS_MODAL_CLICK = event`Click on modal button`
export const SPECIAL_SALE_OFFERS_PRODUCT_IN_MODAL_CLICK = event`Click on product of modal`

export const SEARCH_BOX_AUTO_SUGGEST_QUERY_SUGGESTION_CLICK = event`SearchBox - Click on auto suggest query suggestion`
export const ACCOUNT_OVERVIEW_DOWNLOAD_INVOICE = event`Account Overview - Click on download invoice`
export const ACCOUNT_OVERVIEW_HELP_WITH_MY_ORDER = event`Account Overview - Click on help with my order`
export const ACCOUNT_OVERVIEW_LAST_ORDER_DETAIL = event`Account Overview - Click on last order detail`

export const ORDER_HELP_MODAL_LINK_EVENT = event`order-help-modal-link`
export const ORDER_CANCELLATION_MODAL_CLICK_EVENT = event`ORDER_CANCELLATION - Click on cancellation link order detail`
export const ORDER_RETURN_MODAL_CICK_EVENT = event`order-help-return-label-modal-link-click`
export const ONE_TIME_PASSWORD_INTERESTED_EVENT = event`ONE_TIME_PASSWORD_INTERESTED`
export const NEW_SELLER_LINK_CLICK = event`Click on new seller link`
export const NOTEPAD_TO_CART_DEFAULT = event`Click from Notepad to the cart from services list`
export const NOTEPAD_TO_CART_WIDGET = event`Click from Notepad to the cart from widget`
export const NOTEPAD_EMPTY_FROM_DEFAULT = event`Click from default to empty notepad`
export const SEARCH_BOX_SUGGESTED_PRODUCT_CLICK = event`SearchBox - Click on suggested product`

// passkey fake door events
export const LOGIN_PASSKEY_BUTTON_CLICKED = event`LOGIN_PASSKEY_BUTTON_CLICKED`
export const LOGIN_PASSKEY_DONT_KNOW_BUTTON_CLICKED = event`LOGIN_PASSKEY_DONT_KNOW_BUTTON_CLICKED`
export const LOGIN_PASSKEY_DISLIKE_BUTTON_CLICKED = event`LOGIN_PASSKEY_DISLIKE_BUTTON_CLICKED`
export const LOGIN_PASSKEY_LIKE_BUTTON_CLICKED = event`LOGIN_PASSKEY_LIKE_BUTTON_CLICKED`

export const REGISTER_PASSKEY_BUTTON_CLICKED = event`REGISTER_PASSKEY_BUTTON_CLICKED`
export const REGISTER_PASSKEY_LIKE_BUTTON_CLICKED = event`REGISTER_PASSKEY_LIKE_BUTTON_CLICKED`
export const REGISTER_PASSKEY_DISLIKE_BUTTON_CLICKED = event`REGISTER_PASSKEY_DISLIKE_BUTTON_CLICKED`
export const REGISTER_PASSKEY_DONT_KNOW_BUTTON_CLICKED = event`REGISTER_PASSKEY_DONT_KNOW_BUTTON_CLICKED`

//erx modal events
export const ERX_MODAL_CLOSED = event`Overlay - Click on close`
export const ERX_MODAL_SEEN = event`Overlay - Seen`
export const ERX_MODAL_CTA_CLICKED = event`Overlay - Click on CTA button`
