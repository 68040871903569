import {
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_CART,
  PAGE_TYPE_CATEGORY,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_FORGOT_PASSWORD,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_NEWSLETTER,
  PAGE_TYPE_SEARCH_RESULTS,
  TENANT_COM,
} from 'shared/consts'
import {
  ERX_MODAL_CLOSED,
  ERX_MODAL_CTA_CLICKED,
  ERX_MODAL_EXPERIMENT,
  ERX_MODAL_SEEN,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import { activateExperiment } from 'shared/experiments/utils/experiments'
import { getExperimentPerTenantOnClient } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

const ERX_MODAL_ID = 'ErxModal'
const ERX_MODAL_TIMEOUT = 10000

const blacklistedPages = [
  PAGE_TYPE_ACCOUNT,
  PAGE_TYPE_FORGOT_PASSWORD,
  PAGE_TYPE_NEWSLETTER,
  PAGE_TYPE_CART,
  PAGE_TYPE_CHECKOUT,
  PAGE_TYPE_LOGIN,
  PAGE_TYPE_SEARCH_RESULTS,
  PAGE_TYPE_CATEGORY,
]
const blacklistedExpas = ['dvd']

const trackClicks = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (target.closest('.o-ErxModal__MoreInfoButton')) {
    trackEvent(ERX_MODAL_CTA_CLICKED)
  } else if (target.closest('.o-ErxModal__modalCloser')) {
    trackEvent(ERX_MODAL_CLOSED)
  }
}

const modalCloseEventHandler = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (
    !target.closest('.m-Modal__content') &&
    window.location.hash === `#${ERX_MODAL_ID}`
  ) {
    window.location.hash = ''
  }
}

export const triggerErxModal = (timeout: number) => {
  document.addEventListener('click', modalCloseEventHandler)

  setTimeout(function () {
    const scrollPosition = window.scrollY
    window.location.hash = ERX_MODAL_ID
    window.sessionStorage.setItem(ERX_MODAL_ID, 'true')
    window.dataLayer.push({ 'event': 'popin', 'popinName': '__eRxModal' })

    trackEvent(ERX_MODAL_SEEN)

    // scroll to original position after modal is closed
    window.addEventListener('hashchange', function () {
      if (window.location.hash !== `#${ERX_MODAL_ID}`) {
        window.scrollTo(0, scrollPosition)
        document.removeEventListener('click', modalCloseEventHandler)
      }
    })

    document.addEventListener('click', trackClicks)
  }, timeout)
}

const activateErxModalExperiment = (erxModalExperiment: string) => {
  setTimeout(() => {
    void activateExperiment(erxModalExperiment)
  }, ERX_MODAL_TIMEOUT)
}

export const isPageBlacklisted = (page: string) =>
  blacklistedPages.includes(page)

export default () => {
  const isErxModalShown = window.sessionStorage.getItem(ERX_MODAL_ID)
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { tenant } = publicRuntimeConfig?.publicConfig || {}
  const { expa = '' } = publicRuntimeConfig?.pageProperties || {}
  const { pageType = '', coreShopEnvironment } =
    publicRuntimeConfig?.pageProperties || {}
  const {
    isEnabled,
    variant,
    name: erxModalExperiment,
  } = getExperimentPerTenantOnClient(ERX_MODAL_EXPERIMENT)

  if (
    isPageBlacklisted(pageType) ||
    blacklistedExpas.includes(expa) ||
    isErxModalShown ||
    tenant !== TENANT_COM ||
    coreShopEnvironment === 'qs'
  ) {
    return
  }

  //Activation after timeout
  if (isEnabled && variant === EXPERIMENT_VARIATION.V2) {
    setTimeout(() => {
      void activateExperiment(erxModalExperiment)
    }, ERX_MODAL_TIMEOUT)

    return
  }

  if (isEnabled && variant === EXPERIMENT_VARIATION.V1) {
    let pageVisits = parseInt(
      window.sessionStorage.getItem('erxModalExperimentPageVisitCount') || '0',
      10
    )
    pageVisits += 1
    window.sessionStorage.setItem(
      'erxModalExperimentPageVisitCount',
      pageVisits.toString()
    )
    activateErxModalExperiment(erxModalExperiment)

    // Show modal only on the second page visit
    if (pageVisits === 2) {
      triggerErxModal(0)
    }
  } else {
    triggerErxModal(ERX_MODAL_TIMEOUT)

    if (isEnabled) {
      activateErxModalExperiment(erxModalExperiment)
    }
  }
}
