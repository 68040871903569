// layout (each page): top
export const AD_SLOT_GLOBAL_TOP = 'sw-hb'

// home page: CategoryWide CMS component
export const AD_SLOT_HOMEPAGE_WIDE = 'hp-b1'
// home page: CategorySmall CMS component
export const AD_SLOT_HOMEPAGE_SMALL_1 = 'hp-b2'
export const AD_SLOT_HOMEPAGE_SMALL_2 = 'hp-b3'

export const AD_SLOT_HOMEPAGE_SMALL_5 = 'hp-b5'
export const AD_SLOT_HOMEPAGE_SMALL_6 = 'hp-b6'

// home page: EyecatcherSmart CMS component
export const AD_SLOT_HOMEPAGE_SMART = 'hp-smart'
// home page: Services Widget CMS components Banner
export const AD_SLOT_HOMEPAGE_SERVICES_BANNER = 'hp-b4'

// category page: CategoryWide CMS component
export const AD_SLOT_CATEGORY_WIDE = 'cp-b1'
// category page: CategorySmall CMS component
export const AD_SLOT_CATEGORY_SMALL_1 = 'cp-b2'
export const AD_SLOT_CATEGORY_SMALL_2 = 'cp-b3'

export const AD_SLOT_PROMO_SMALL_1 = 'pp-b1'
export const AD_SLOT_PROMO_SMALL_2 = 'pp-b2'
export const AD_SLOT_PROMO_SMALL_3 = 'pp-b3'
export const AD_SLOT_PROMO_SMALL_4 = 'pp-b4'
export const AD_SLOT_PROMO_SMALL_5 = 'pp-b5'
export const AD_SLOT_PROMO_SMALL_6 = 'pp-b6'
export const AD_SLOT_PROMO_SMALL_7 = 'pp-b7'
export const AD_SLOT_PROMO_SMALL_8 = 'pp-b8'

// search page (brand product listing): top
export const AD_SLOT_SEARCH_BRAND_TOP = 'ms-b1'
// search page (category product listing): top
export const AD_SLOT_SEARCH_CATEGORY_TOP = 'cpl-b1'

export const AD_SLOT_SEARCH_TOP_BANNER = 'pl-b3'
// search page: between search results
export const AD_SLOT_SEARCH_HITS_1 = 'pl-b1'
export const AD_SLOT_SEARCH_HITS_2 = 'pl-b2'
// product page: cross sell-widget
export const AD_SLOT_PDP_CROSSSELL = 'pds-b1'
// product page: RX message
export const AD_SLOT_PDP_RX = 'pds-b1-rx'
// order confirmation page
export const AD_SLOT_CONFIRMATION = 'ty-b1'
// cart internidiate page
export const AD_SLOT_CARTINTERMIDIATE = 'wkzs-b1'
// top banner
export const AD_SLOT_TOP_BANNER = 'top-banner'
