import { QS, TENANT_CH, TENANT_FR, TENANT_IT } from 'shared/consts'
import { NEWSLETTER_MODAL_ID } from 'shared/experiments/browser/newsletterModal/consts'
import {
  getIsSourceBlacklisted,
  triggerNewsletterModal,
} from 'shared/experiments/browser/newsletterModal/utils'
import { NEWSLETTER_MODAL } from 'shared/experiments/consts'
import { getExperimentPerTenantOnClient } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import store from 'shared/store'

export default () => {
  const isNLModalShown = window.sessionStorage.getItem(NEWSLETTER_MODAL_ID)
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { tenant } = publicRuntimeConfig?.publicConfig || {}
  const {
    pageType = '',
    searchUrl,
    coreShopEnvironment,
  } = publicRuntimeConfig?.pageProperties || {}

  const forceNewsletterModalOnQs = searchUrl?.includes('forceModalOnQs=true')

  if (
    getIsSourceBlacklisted(pageType, searchUrl) ||
    isNLModalShown ||
    ![TENANT_CH, TENANT_IT, TENANT_FR].includes(tenant) ||
    (coreShopEnvironment === QS && !forceNewsletterModalOnQs)
  ) {
    return
  }

  const newsletterExperiment = getExperimentPerTenantOnClient(NEWSLETTER_MODAL)

  if (!newsletterExperiment.isEnabled) {
    return
  }

  triggerNewsletterModal()
}
