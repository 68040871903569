import { PAGE_TYPE_CART_INTERMEDIATE } from 'shared/consts'
import { OFFER_TYPE_MIRAKL } from 'shared/consts/marketplace'
import {
  ALL_PRODUCTS_FROM_SELLER_EXPERIMENT,
  EXPERIMENT_VARIATION,
  NEW_SELLER_LINK_CLICK,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const { pageType = '', product } = publicRuntimeConfig?.pageProperties || {}
  const allProductsFromSellerExperiment = getExperimentNamePerTenant(
    ALL_PRODUCTS_FROM_SELLER_EXPERIMENT,
    tenant,
    deviceClass
  )
  const { isEnabled, variant } = getExperimentOnClient(
    allProductsFromSellerExperiment
  )
  const currentOffer = product?.offers?.find(
    offer => offer.offerId === product?.offerId
  )
  const isMarketplaceProduct = currentOffer?.offerType === OFFER_TYPE_MIRAKL

  if (
    !isEnabled ||
    pageType !== PAGE_TYPE_CART_INTERMEDIATE ||
    !isMarketplaceProduct
  ) {
    return
  }

  void activateExperiment(allProductsFromSellerExperiment)

  if (
    variant === EXPERIMENT_VARIATION.V1 ||
    variant === EXPERIMENT_VARIATION.V2
  ) {
    const sellerLinkBox = <HTMLElement>(
      document.querySelector(`.e-sellerLinkBox-${variant}`)
    )
    sellerLinkBox.addEventListener('click', event => {
      const element = <HTMLElement>event.target
      if (element.closest('.e-sellerLink')) {
        trackOptimizelyEvent(NEW_SELLER_LINK_CLICK)
      }
    })
  }
}
