// @ts-nocheck
import type {
  DiscountPromotionTypeEnum,
  Origin,
} from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models/Model'

import { OrderDiscountV1 } from './ordersResponseTypes'

export class OrderDiscount extends Model {
  public readonly affectedProductIds: string[]
  public readonly amount: number
  public readonly displayName: string
  public readonly discountId: string
  public readonly type: string
  public readonly promotionType?: DiscountPromotionTypeEnum
  public readonly origin?: Origin

  constructor(src: OrderDiscountV1) {
    super(src)
    this.affectedProductIds = src.affectedProductIds
    this.amount = src.amount
    this.displayName = src.displayName
    this.discountId = src.discountId
    this.type = src.type
    this.promotionType = src.promotionType
    this.origin = src.origin
  }
}
