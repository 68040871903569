import type {
  OfferV1,
  PimProductV1,
  VolumeBasedPriceV1,
} from '@redteclab/api/clients/bully'

import { type SearchHit } from 'types/search'

export interface RangesData {
  discountPercentage?: number
  id: number
  price: number
  range: string
  rangeEnd?: number
  rangeStart?: number
}

export const getEntryHasVolumePricing = (entry: SearchHit): boolean => {
  const volumeBasedPrices: VolumeBasedPriceV1[] =
    entry?.best_offer?.volume_based_prices || []

  return volumeBasedPrices.length > 1
}

// bestOffer is the one that is displayed in SERP
const getBestOfferFromProduct = (
  product: PimProductV1
): OfferV1 | undefined => {
  return product.offers?.find(offer => offer?.offerFlags?.includes('bestOffer'))
}

export const getVolumePricingFromBestOffer = (
  product: PimProductV1
): VolumeBasedPriceV1[] => {
  return getBestOfferFromProduct(product)?.volumeBasedPrices ?? []
}

export const useGetDiscountRanges = (
  volumePrices: VolumeBasedPriceV1[]
): RangesData[] | undefined => {
  const sortedVolumePrices = [...volumePrices].sort(
    (firstPrice, secondPrice) =>
      (firstPrice.quantityThreshold ?? 0) - (secondPrice.quantityThreshold ?? 0)
  )

  return sortedVolumePrices.map((discount, index) => {
    const rangeStart = discount.quantityThreshold
    const nextDiscount =
      index + 1 < sortedVolumePrices.length
        ? sortedVolumePrices[index + 1]
        : undefined
    const rangeEnd = nextDiscount?.quantityThreshold
      ? nextDiscount.quantityThreshold - 1
      : undefined
    const discountPercentage = discount.discountPercentage ?? 0

    const range =
      rangeEnd && rangeStart !== rangeEnd
        ? `${rangeStart}-${rangeEnd}`
        : `${rangeStart}`
    const price =
      index === 0 && discount.unitDiscountPrice
        ? discount.unitDiscountPrice
        : discount.unitOriginPrice ?? 0

    return {
      discountPercentage,
      id: index,
      price,
      range,
      rangeStart,
      rangeEnd,
    }
  })
}

interface DiscountPercentageData {
  currentDiscountPercentage: number | undefined
  firstDiscountPercentage: number | undefined
}
export const useGetDiscountPercentages = (
  ranges: RangesData[],
  selectedQuantity: number
): DiscountPercentageData => {
  const getFirstDiscountPercentage = (): number | undefined => {
    const firstRange = ranges.find(range => range.discountPercentage !== 0)

    if (!firstRange) {
      return undefined
    }

    return firstRange.discountPercentage
  }

  const getCurrentDiscountPercentage = (): number | undefined => {
    const currentRange = ranges.find(
      range =>
        range.rangeStart !== undefined &&
        selectedQuantity >= range.rangeStart &&
        (range.rangeEnd === undefined || selectedQuantity <= range.rangeEnd)
    )

    if (!currentRange) {
      return undefined
    }

    return currentRange.discountPercentage
  }

  return {
    currentDiscountPercentage: getCurrentDiscountPercentage(),
    firstDiscountPercentage: getFirstDiscountPercentage(),
  }
}
