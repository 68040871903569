// This function checks if passed element is entirely in the viewport. Function will return false if passed element is bigger than the viewport itself or it is only partially visible.

const isElementEntirelyInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export default isElementEntirelyInViewport
