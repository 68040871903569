import type { PimVariantAttributeV1 } from '@redteclab/api/clients/bully'

import { Model } from 'shared/services/api/models/Model'
import { getPlaceholderImage } from 'shared/utils/imageUtils'

import { OrderDocumentPricePerUnit } from './orderDocumentPricePerUnit'
import type { OrderDocumentPositionV1 } from './ordersResponseTypes'

export class OrderDocumentPosition extends Model {
  public readonly amountRedpoints: number
  public readonly availability: string
  public readonly canonicalURL: string
  public readonly cartInsertOrder: number
  public readonly isCoolingRequired: boolean
  public readonly lineNum: number
  public readonly pzn: string
  public readonly itemName: string
  public readonly quantity: number
  public readonly salesPrice: number
  public readonly lineDiscountPercent: number
  public readonly lineAmount: number
  public readonly itemTax: number
  public readonly prescriptionBonus: number
  public readonly packagingUnit: string
  public readonly packagingQuantity: number
  public readonly pharmaForm: string
  public readonly pricePerUnit?: OrderDocumentPricePerUnit
  public readonly thumbnailURL: string
  public readonly upid: string
  public readonly entryType: string
  public readonly redPointsMultiplier: number
  public readonly isFreebie: boolean
  public readonly subtitle?: string
  public readonly variantAttributes?: PimVariantAttributeV1[]
  public readonly discountAmountPerItem?: number
  public readonly discountedPrice?: number
  public readonly discountedLineAmount?: number
  public readonly lineDiscountedLineAmount?: number
  public readonly offerId?: string
  public readonly lineDiscountedPriceDifferenceInPercent?: number
  public readonly isCancellable: boolean
  public readonly isClaimable: boolean

  constructor(src: OrderDocumentPositionV1) {
    super(src)
    this.amountRedpoints = src.amountRedpoints
    this.availability = src.availability
    this.canonicalURL = src.canonicalURL
    this.cartInsertOrder = src.cartInsertOrder
    this.isCoolingRequired = Boolean(src.isCoolingRequired)
    this.lineNum = src.lineNum
    this.pzn = src.pzn
    this.itemName = src.itemName
    this.quantity = src.quantity
    this.salesPrice = src.salesPrice
    // TODO: Remove the percentage rounding when it is done by the service
    this.lineDiscountPercent = Math.round(src.lineDiscountPercent)
    this.lineAmount = src.lineAmount
    this.itemTax = src.itemTax
    this.prescriptionBonus = src.prescriptionBonus
    this.packagingUnit = src.packagingUnit
    this.packagingQuantity = src.packagingQuantity
    this.pharmaForm = src.pharmaForm
    if (src.pricePerUnit?.formatted) {
      this.pricePerUnit = new OrderDocumentPricePerUnit(src.pricePerUnit)
    }
    this.thumbnailURL = src.thumbnailURL || getPlaceholderImage(false)
    this.upid = src.upid
    this.entryType = src.entryType
    this.redPointsMultiplier = src.redPointsMultiplier
    this.isFreebie = src.isFreebie
    this.variantAttributes = src.variantAttributes
    this.discountAmountPerItem = src.discountAmountPerItem
    this.discountedLineAmount = src.discountedLineAmount
    this.discountedPrice = src.discountedPrice
    this.lineDiscountedLineAmount = src.lineDiscountedLineAmount
    this.offerId = src.offerId
    this.lineDiscountedPriceDifferenceInPercent =
      src.lineDiscountedPriceDifferenceInPercent
    this.isCancellable = src.isCancellable
    this.isClaimable = src.isClaimable
  }
}
